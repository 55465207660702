
import { Vue } from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
import AppActions from '@/domain/constants/AppActions'

export default Vue.extend({
  name: 'DocumentationWrapper',
  props: {
    type: {
      type: String,
      default: 'docs',
    },
    title: {
      type: String,
      default: "Documentation"
    },
    page: {
      type: Object,
      default: null,
    },
    link: {
      type: String,
      default: '',
    },
    isHomepage: {
      type: Boolean,
      default: true,
    },
    chapters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      scrollContainer: null,
      websiteOrigin: this.$config.wpBaseURL,
      nuxtBaseURL: this.$config.nuxtBaseURL,
      docsWidth: 0,
    }
  },
  computed: {
    ...mapGetters({
      docsChapters: 'docs/getChapters',
      academyChapters: 'academy/getChapters',
    }),
  },
  methods: {
    changeLink(link: any) {
      if (link && link.url && link.url !== window.location.pathname) {
        const url = link.url.replaceAll('//', '/')
        this.$router.push(url)
        this.trackEventDocsView(link)
      }
    },
    trackEventDocsView: function (link: any): void {
      if (link && link.url) {
        const category = 'Docs'
        const action =
          link.type === 'prev'
            ? 'Previous'
            : link.type === 'next'
            ? 'Next'
            : 'Page View'
        const title = link.title || link.url
        const label = `standalone ${title}`

        const segmentData = {
          callback: () => {},
          openInNewTab: false,
          name: `${category} - ${action}`,
          category: category,
          action: action,
          label: label,
          misc: [
            { type: 'page', value: title },
            { type: 'location', value: 'standalone' },
          ],
          integrations: '',
        }
        this.$store.dispatch(AppActions.TRACK_SEGMENT_EVENT, {
          context: this,
          params: segmentData,
        })
      }
    },
  },
})
