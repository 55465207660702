import { Component, Vue, Watch } from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
import { UserType } from '@interfaces/UserTypes'
import { decodeHTMLEntities } from '~/domain/helpers'

@Component({
  name: 'MachinationsBasePage',
  layout: 'machinations',
  computed: {
    ...mapGetters({
      userIsAuthenticated: 'userIsAuthenticated',
      authenticatedUser: 'authenticatedUser',
    }),
  },
})
class MachinationsBasePage extends Vue {
  authenticatedUser!: UserType
  userIsAuthenticated!: boolean

  baseHead() {
    const ogFilter = (s: string) =>
      s
        ?.replace(
          this.$config?.wpBaseURL?.replace('https://', ''),
          this.$config?.nuxtBaseURL?.replace('https://', '')
        )
        ?.replaceAll('&#8226;', '•')
        ?.replaceAll('&#8211;', '-')
        ?.replace('- Nuxt', '')

    const wpPageHead = this.$store.state.wp.pageHead
    const wpOgTitle = ogFilter(
      decodeHTMLEntities(
        this.$store.state.wp.pageTitle || wpPageHead?.og_title || ''
      )
    )
    const wpOgUrl =
      this.$config?.nuxtBaseURL +
      (this.$route.path !== '/' ? this.$route.path : '')

    let og_image = wpPageHead.og_image
    if (Array.isArray(wpPageHead.og_image) && wpPageHead.og_image.length > 0) {
      og_image = wpPageHead.og_image[0]
    }
    const ogImageMetas = og_image
      ? [
          {
            hid: 'og:image',
            name: 'og:image',
            property: 'og:image',
            content: og_image?.url,
          },
          {
            hid: 'og:image:width',
            name: 'og:image:width',
            content: og_image?.width,
          },
          {
            hid: 'og:image:height',
            name: 'og:image:height',
            content: og_image?.height,
          },
          {
            hid: 'og:image:type',
            name: 'og:image:type',
            content: og_image?.type,
          },
        ]
      : []
    const ogMetas = [
      { hid: 'og:locale', name: 'og:locale', content: wpPageHead.og_locale },
      { hid: 'og:type', name: 'og:type', content: wpPageHead.og_type },
      { hid: 'og:title', name: 'og:title', content: wpOgTitle },
      {
        hid: 'og:description',
        name: 'og:description',
        property: 'og:description',
        content: wpPageHead.og_description,
      },
      {
        hid: 'og:site_name',
        name: 'og:site_name',
        property: 'twitter:title',
        content: ogFilter(wpPageHead.og_site_name),
      },
      { hid: 'og:url', name: 'og:url', content: wpOgUrl },
      ...ogImageMetas,

      {
        hid: 'twitter:card',
        name: 'twitter:card',
        content: wpPageHead.twitter_card,
      },
      {
        hid: 'twitter:site',
        name: 'twitter:site',
        content: wpPageHead.twitter_site,
      },
    ]

    let robots = {
      index: 'index',
      follow: 'follow',
      'max-image-preview': 'max-image-preview:large',
      'max-snippet': 'max-snippet:-1',
      'max-video-preview': 'max-video-preview:-1',
      imageindex: '',
      archive: '',
      snippet: '',
    }

    const yoastRobots = wpPageHead.robots
    const wpRobots = this.$store.state.wp.acfData?.robots

    for (const key in yoastRobots) {
      // Exclude the index robots metadata, as it's always 'noindex' due to WordPress' sitewide privacy settings
      if (key !== 'index') {
        robots[key] = yoastRobots[key]
      }
    }

    // Use the index value set in the ACF data
    wpRobots?.index && (robots['index'] = wpRobots?.index)

    return {
      titleTemplate: '%s • Machinations.io',
      title: wpOgTitle,
      meta: [
        { hid: 'charset', charset: 'utf-8' },
        {
          hid: '',
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
        {
          hid: 'description',
          name: 'description',
          content: wpPageHead.og_description,
        },
        {
          hid: 'robots',
          name: 'robots',
          content: Object.values(robots)
            .filter((val) => val !== '')
            .join(', '),
        },
        ...ogMetas,
      ],
      link: [{ rel: 'canonical', href: wpOgUrl }],
    }
  }

  mounted() {
    this.$store.commit('common/isBusy', false)
  }
}

export default MachinationsBasePage
