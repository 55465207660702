
import { Context } from '@nuxt/types'
import MachinationsLightPage from '~/domain/pages/MachinationsLightPage'

export default MachinationsLightPage.extend({
  name: 'DocumentationIndex',
  layout: 'docsLayout',
  head() {
    // @ts-ignore
    const pageHead = this.pageHead()
    return {
      ...pageHead,
      title: `${this.$t('capitalDocs')}`,
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: `${this.$t('capitalDocs')}`,
        },
        ...pageHead?.meta,
      ],
    }
  },
  key(route) {
    return 'docs-' + route.fullPath
  },
  data() {
    return {
      page: null,
    }
  },
  computed: {},
  methods: {},
  async asyncData(context: Context) {
    const { store } = context

    await store.dispatch('common/setupPageLight', { context })
    let chapters = []
    if (!store.state.docs.chaptersLoaded) {
      chapters = await store.dispatch('docs/loadChapters', {
        context,
      })
    }
    await store.dispatch('wp/fetchPageBySlug', {
      context,
      slug: 'docs',
    })
    return {
      chapters,
    }
  },
})
